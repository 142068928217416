import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { Typography } from '@achieve/ascend'
import { SIGN_IN_ROUTE } from 'constants/navigation'

function SignInLink({ disabledRoutes = [], signInLinkUrl, signInLinkText, event, ...restProps }) {
  if (disabledRoutes.includes(SIGN_IN_ROUTE)) {
    return null
  }

  return (
    <AchieveLink
      color="primary"
      href={signInLinkUrl ? signInLinkUrl : SIGN_IN_ROUTE}
      track={{
        ...{
          list_name: 'Header Nav Sign in Link',
        },
        ...event,
      }}
      variant="bodySm"
      {...restProps}
    >
      <Typography fontWeight="medium">{signInLinkText ? signInLinkText : `Sign in`}</Typography>
    </AchieveLink>
  )
}

export { SignInLink }
