import * as React from 'react'
export function ChevronDown({ title, height = 14, width = 8, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
      <title>Toggle {title}</title>
      <path
        fill="#1F1F23"
        stroke="#1F1F23"
        d="m7 6.132-.354-.353-4.642-4.643a.437.437 0 0 0-.618.618l5.305 5.305a.437.437 0 0 0 .618 0l5.312-5.312L7 6.132Zm0 0 .354-.353M7 6.132l.354-.353m0 0 4.65-4.65-4.65 4.65Zm5.267-4.65a.437.437 0 0 0-.617 0h.617Zm0 0a.437.437 0 0 1 0 .618v-.618Z"
      />
    </svg>
  )
}
export default ChevronDown
