import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'

const useLayoutMenuCondensed = () => {
  const { state, dispatch } = useContext(LayoutContext)

  const setLayoutMenuCondensed = (value) => {
    dispatch({ type: 'SET_MENU_CONDENSED', value })
  }

  return [state.menuCondensed, setLayoutMenuCondensed]
}

export default useLayoutMenuCondensed
