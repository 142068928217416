import * as React from 'react'
export function Menu({ title, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={12} fill="none" {...props}>
      <title>{title}</title>
      <path fill="#1F1F23" d="M0 12h18v-2H0v2Zm0-5h18V5H0v2Zm0-7v2h18V0H0Z" />
    </svg>
  )
}
export default Menu
