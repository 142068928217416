import { useContext } from 'react'
import { HeaderContext } from 'providers/HeaderProvider'

const useMobileNavState = () => {
  const { state: { mobileNavOpen } = {}, dispatch } = useContext(HeaderContext)

  /**
   * Dispatches an action to save a flag for the mobile nav open state
   * @param {boolean|false} open
   */
  const setMobileNavOpen = (open = false) => {
    dispatch({ type: 'SET_MOBILE_NAV_OPEN', open })
  }

  return [mobileNavOpen, setMobileNavOpen]
}

export default useMobileNavState
