import * as React from 'react'
export function Close({ title, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
      <title>{title}</title>
      <path
        fill="#1F1F23"
        d="M11.25 1.808 10.193.75 6 4.942 1.808.75.75 1.808 4.942 6 .75 10.193l1.058 1.057L6 7.058l4.193 4.192 1.057-1.057L7.058 6l4.192-4.192Z"
      />
    </svg>
  )
}
export default Close
