import { Button, Typography } from '@achieve/ascend'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import {
  GET_STARTED_ROUTE,
  getProductKeyByRoute,
  updateQueryStringParameter,
} from 'constants/navigation'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { useRouter } from 'next/router'
import { EVENT_LIST } from 'utils/analytics/sync-event-list'
import {
  OFF_EXPERIMENT,
  UNASSIGNED_EXPERIMENT,
  DEFAULT_EXPERIMENT,
  PRODUCT_SPECIFIC_NAME_EXPERIMENT,
} from 'constants/experiments'

export default function HeaderCta({
  className = '',
  'data-testid': dataTestId,
  linkUrl,
  linkText,
  event,
  ...buttonProps
}) {
  const router = useRouter()
  const productPageValue = getProductKeyByRoute(router.pathname)
  const productSpecificParamExperiment = useFeatureFlag(
    FEATURE_TOGGLES.ACX_ENABLE_PRODUCT_SPECIFIC_PARAM
  )
  const url = linkUrl ?? GET_STARTED_ROUTE
  //Only change final url if experiment is on, not unnassingned or default and product page value is not null
  const changeFinalUrl =
    productSpecificParamExperiment &&
    ![OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT, DEFAULT_EXPERIMENT].includes(
      productSpecificParamExperiment
    ) &&
    productPageValue
  const finalUrl = changeFinalUrl
    ? updateQueryStringParameter(url, 'section', productPageValue)
    : url

  // Add experiment to the CTA only if the experiment is on
  const experimentCTA =
    productPageValue !== '' &&
    productSpecificParamExperiment &&
    OFF_EXPERIMENT !== productSpecificParamExperiment
      ? [
          {
            experiment_name: PRODUCT_SPECIFIC_NAME_EXPERIMENT,
            experiment_id: FEATURE_TOGGLES.ACX_ENABLE_PRODUCT_SPECIFIC_PARAM,
            variation: productSpecificParamExperiment,
            event_type: 'start',
          },
        ]
      : []
  return (
    <AchieveLink
      className={className}
      href={finalUrl}
      data-testid={dataTestId}
      track={{
        ...{
          list_name: EVENT_LIST.HEADER_NAV_MAIN_CTA_BUTTON,
          experiments: experimentCTA,
        },
        ...event,
      }}
    >
      <Button color="primary" variant="contained" {...buttonProps}>
        <Typography variant="bodySm" fontWeight="bold">
          {linkText ? linkText : `Get Started`}
        </Typography>
      </Button>
    </AchieveLink>
  )
}
