import { useContext } from 'react'
import { HeaderContext } from 'providers/HeaderProvider'

const useActiveNavItemIndex = () => {
  const { state, dispatch } = useContext(HeaderContext)

  const setActiveIndex = (itemIndex) => {
    dispatch({ type: 'SET_ACTIVE_NAV_ITEM_INDEX', itemIndex })
  }

  return [state.activeItemIndex, setActiveIndex]
}

export default useActiveNavItemIndex
